<h2>Settings</h2>
<table class="p-4">
  <tr>
    <td>Remember settings</td>
    <td>
      <mat-slide-toggle
        color="primary"
        [checked]="settings.remember()"
        (change)="settings.updateRemember($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td>Dark mode</td>
    <td>
      <mat-slide-toggle
        color="primary"
        [checked]="settings.darkMode()"
        (change)="settings.updateDarkMode($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td>Theme</td>
    <td>
      <mat-form-field class="example-full-width">
        <mat-label>Theme</mat-label>
        <mat-select
          [value]="settings.theme()"
          (selectionChange)="settings.updateTheme($event.value)"
        >
          @for (theme of settings.themeList() | keyvalue; track theme) {
            <mat-option [value]="theme.value ?? ''">{{ theme.key }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </td>
  </tr>
  <tr>
    <td>Default timezone</td>
    <td>
      <mat-form-field class="example-full-width">
        <mat-label>Timezone</mat-label>
        <input
          #input
          type="text"
          placeholder="Pick one"
          matInput
          [matAutocomplete]="auto"
          [formControl]="timezoneControl"
          (input)="filter(input.value)"
          (focus)="filter(input.value)"
        />
        <mat-autocomplete #auto="matAutocomplete">
          @for (option of filteredTimezoneOptions; track option) {
            <mat-option [value]="option">{{ option }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </td>
  </tr>
  <tr>
    <td>Router tracing</td>
    <td>
      <mat-slide-toggle
        color="primary"
        [checked]="settings.routerTracing()"
        (change)="settings.updateRouterTracing($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td>Log Build info</td>
    <td>
      <mat-slide-toggle
        color="primary"
        [checked]="settings.buildInformationLog()"
        (change)="settings.updateBuildInformationLog($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
</table>
