import {
  signalStoreFeature,
  withState,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, tap } from 'rxjs';
import { Settings as luxonSettings, SystemZone } from 'luxon';

export type TimezoneState = { defaultZone: string | null };

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withLuxonTimezone<_>() {
  return signalStoreFeature(
    withState<TimezoneState>({ defaultZone: null }),

    withMethods((store) => ({
      updateTimezone(defaultZone: string | null): void {
        patchState(store, () => ({ defaultZone: defaultZone }));
      },
      _applyDefaultTimezone: rxMethod<string | null>(
        pipe(
          tap((defaultZone) => {
            if (defaultZone) {
              luxonSettings.defaultZone = defaultZone;
            } else {
              luxonSettings.defaultZone = new SystemZone();
            }
          }),
        ),
      ),
    })),

    withHooks({
      onInit(store) {
        store._applyDefaultTimezone(store.defaultZone);
      },
    }),
  );
}
