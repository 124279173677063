import { inject } from '@angular/core';
import { BuildInfoService } from '@cca-common/core';
import {
  patchState,
  signalStoreFeature,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, tap } from 'rxjs';

export type BuildInformationState = { buildInformationLog: boolean };

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withBuildInformation<_>() {
  return signalStoreFeature(
    withState<BuildInformationState>({ buildInformationLog: false }),

    withMethods((store) => {
      const buildInfoService = inject(BuildInfoService, {
        optional: true,
      });
      return {
        updateBuildInformationLog(buildInformationLog: boolean): void {
          patchState(store, () => ({
            buildInformationLog: buildInformationLog,
          }));
        },
        _applyBuildInformationLog: rxMethod<boolean>(
          pipe(
            tap((enabled) => {
              if (enabled) {
                buildInfoService?.printBuildInfo();
              }
            }),
          ),
        ),
      };
    }),

    withHooks({
      onInit(store) {
        store._applyBuildInformationLog(store.buildInformationLog);
      },
    }),
  );
}
