import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import {
  signalStoreFeature,
  withMethods,
  withState,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, tap } from 'rxjs';

export type ThemeState = {
  theme: string | null;
  themeList: Record<string, string | null>;
};

const themeList: Record<string, string | null> = {
  CtrlChain: null,
  NewCold: 'newcold',
};

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withThemes<_>() {
  return signalStoreFeature(
    withState<ThemeState>({
      theme: null,
      themeList: themeList,
    }),
    withMethods((store, document = inject(DOCUMENT)) => ({
      updateTheme(theme: string | null): void {
        patchState(store, () => ({ theme: theme }));
      },
      _applyTheme: rxMethod<string | null>(
        pipe(
          tap((themeName) => {
            const htmlElement = document.body.parentElement;
            if (!htmlElement) {
              return;
            }

            const classesToRemove: string[] = [];
            htmlElement.classList.forEach((value) => {
              if (value.startsWith('theme')) {
                classesToRemove.push(value);
              }
            });
            for (const c of classesToRemove) {
              htmlElement.classList.remove(c);
            }

            if (themeName) {
              htmlElement.classList.add(`theme-${themeName}`);
            }
          }),
        ),
      ),
    })),

    withHooks({
      onInit(store) {
        store._applyTheme(store.theme);
      },
    }),
  );
}
